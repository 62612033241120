import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useMainContext } from './MainContext';

const StepBasedContext = createContext();

export function StepBasedContextWrapper({ children }) {
  const { projectInformation } = useMainContext();

  const [MOREFUCKINGSTEPS, setMOREFUCKINGSTEPS] = useState([]);
  useEffect(() => {
    Promise.resolve(import('./MOREFUCKINGSTEPS.json')).then((data) => {
      setMOREFUCKINGSTEPS(data.default);
    });
  }, []);

  // console.log(MOREFUCKINGSTEPS.filter((mfs) => mfs.meshpath.startsWith('D_-_')));

  const [steps, setSteps] = useState([]);
  const [currentPart, setCurrentPart] = useState();
  const [currentPartRefs, setCurrentPartRefs] = useState();
  const [currentStepInfo, setCurrentStepInfo] = useState();
  const value = useMemo(
    () => ({
      steps,
      setSteps,
      currentPart,
      setCurrentPart,
      currentPartRefs,
      setCurrentPartRefs,
      currentStepInfo,
      setCurrentStepInfo
    }),
    [currentPart, currentPartRefs, currentStepInfo, steps]
  );

  useEffect(
    () =>
      setSteps(
        projectInformation?.id === 1
          ? [
              {
                id: 0,
                name: 'Voorbereiding',
                changes: [
                  { meshpath: 'Solid11122', name: '' },
                  { meshpath: 'Solid11121', name: '' },
                  { meshpath: 'Solid11120', name: '' },
                  { meshpath: 'Solid11154', name: '' },
                  { meshpath: 'Solid11155', name: '' },
                  { meshpath: 'Solid11156', name: '' },
                  { meshpath: 'Solid11188', name: '' },
                  { meshpath: 'Solid11189', name: '' },
                  { meshpath: 'Solid11190', name: '' },
                  { meshpath: 'Solid11139', name: '' },
                  { meshpath: 'Solid11138', name: '' },
                  { meshpath: 'Solid11137', name: '' },
                  { meshpath: 'Solid11173', name: '' },
                  { meshpath: 'Solid11172', name: '' },
                  { meshpath: 'Solid11171', name: '' },
                  { meshpath: 'Solid11207', name: '' },
                  { meshpath: 'Solid11206', name: '' },
                  { meshpath: 'Solid11205', name: '' }
                ],
                description: 'Tijd om te beginnen aan de schakelkast. Denk goed aan de actiepunten.',
                checklist: [
                  { id: 4, value: 'Veiligheidsvoorwaarden toegepast' },
                  { id: 2, value: 'Paneel juist uitgelijnd op werkbank' },
                  { id: 0, value: 'Gereedschap gereed' },
                  { id: 1, value: 'Onderdelen gereed' }
                ],
                files: [
                  { id: 1, value: './test.pdf', title: 'Extra-Information.pdf', type: 'pdf' },
                  {
                    id: 0,
                    value: './img/step-demo/Instructievideo.png',
                    type: 'img',
                    title: 'Instructievideo'
                  }
                ]
              },
              {
                id: 1,
                name: 'Voorbereiding',
                changes: [
                  { meshpath: 'Solid1720', name: '' },
                  { meshpath: 'Solid1897', name: '' },
                  { meshpath: 'Solid1569', name: '' }
                ],
                description: 'Tijd om te beginnen aan de schakelkast. Denk goed aan de actiepunten.',
                checklist: [],
                files: []
              },
              {
                id: 7,
                name: 'Voorbereiding',
                changes: [
                  { meshpath: 'Solid193', name: '' },
                  { meshpath: 'Solid1348', name: '' },
                  { meshpath: 'Solid1349', name: '' }
                ],
                description: 'Tijd om te beginnen aan de schakelkast. Denk goed aan de actiepunten.',
                checklist: [],
                files: []
              },
              {
                id: 2,
                name: 'Voorbereiding',
                changes: [
                  {
                    meshpath: 'C-1-967325-1',
                    name: ''
                  },
                  {
                    meshpath: 'C-965785-1',
                    name: ''
                  },
                  {
                    meshpath: 'C-1-967402-1',
                    name: ''
                  },
                  {
                    meshpath: 'C-965785-1_1',
                    name: ''
                  },
                  {
                    meshpath: 'C-0965687-01-3',
                    name: ''
                  },
                  {
                    meshpath: 'C-1-967325-1_1',
                    name: ''
                  },
                  {
                    meshpath: 'C-965785-1_2',
                    name: ''
                  },
                  {
                    meshpath: 'C-1-967402-1_1',
                    name: ''
                  },
                  {
                    meshpath: 'C-965785-1_3',
                    name: ''
                  },
                  {
                    meshpath: 'C-0965687-01-3_1',
                    name: ''
                  },
                  {
                    meshpath: 'C-1-967325-1_2',
                    name: ''
                  },
                  {
                    meshpath: 'C-965785-1_4',
                    name: ''
                  },
                  {
                    meshpath: 'C-1-967402-1_2',
                    name: ''
                  },
                  {
                    meshpath: 'C-965785-1_5',
                    name: ''
                  },
                  {
                    meshpath: 'C-0965687-01-3_2',
                    name: ''
                  },
                  {
                    meshpath: 'C-1-967325-1_3',
                    name: ''
                  },
                  {
                    meshpath: 'C-965785-1_6',
                    name: ''
                  },
                  {
                    meshpath: 'C-1-967402-1_3',
                    name: ''
                  },
                  {
                    meshpath: 'C-965785-1_7',
                    name: ''
                  },
                  {
                    meshpath: 'C-0965687-01-3_3',
                    name: ''
                  },
                  {
                    meshpath: 'C-1-967325-1_4',
                    name: ''
                  },
                  {
                    meshpath: 'C-965785-1_8',
                    name: ''
                  },
                  {
                    meshpath: 'C-1-967402-1_4',
                    name: ''
                  },
                  {
                    meshpath: 'C-965785-1_9',
                    name: ''
                  },
                  {
                    meshpath: 'C-0965687-01-3_4',
                    name: ''
                  },
                  {
                    meshpath: 'C-1718230-01-3',
                    name: ''
                  },
                  {
                    meshpath: 'C-965785-1_10',
                    name: ''
                  },
                  {
                    meshpath: 'C-0965687-01-3_5',
                    name: ''
                  },
                  {
                    meshpath: 'C-967650-1',
                    name: ''
                  },
                  {
                    meshpath: 'C-965785-1_11',
                    name: ''
                  },
                  {
                    meshpath: 'C-1-1564407-1',
                    name: ''
                  },
                  {
                    meshpath: 'C-1670866-1',
                    name: ''
                  },
                  {
                    meshpath: 'C-1-1564337-1',
                    name: ''
                  },
                  {
                    meshpath: 'C-1670866-1_1',
                    name: ''
                  }
                ],
                description: 'Tijd om te beginnen aan de schakelkast. Denk goed aan de actiepunten.',
                checklist: [],
                files: []
              },
              {
                id: 3,
                name: 'Plaatsing onderdeel',
                changes: [
                  {
                    meshpath: 'Srf1',
                    name: ''
                  },
                  {
                    meshpath: 'Srf11',
                    name: ''
                  },
                  {
                    meshpath: 'Srf12',
                    name: ''
                  },
                  {
                    meshpath: 'Srf13',
                    name: ''
                  },
                  {
                    meshpath: 'Srf14',
                    name: ''
                  },
                  {
                    meshpath: 'Srf15',
                    name: ''
                  },
                  {
                    meshpath: 'Srf16',
                    name: ''
                  },
                  {
                    meshpath: 'Srf17',
                    name: ''
                  },
                  {
                    meshpath: 'Srf18',
                    name: ''
                  },
                  {
                    meshpath: 'Srf19',
                    name: ''
                  },
                  {
                    meshpath: 'Srf110',
                    name: ''
                  },
                  {
                    meshpath: 'Srf111',
                    name: ''
                  },
                  {
                    meshpath: 'Srf112',
                    name: ''
                  },
                  {
                    meshpath: 'Srf2',
                    name: ''
                  },
                  {
                    meshpath: 'Srf113',
                    name: ''
                  },
                  {
                    meshpath: 'Srf21',
                    name: ''
                  },
                  {
                    meshpath: 'Srf114',
                    name: ''
                  },
                  {
                    meshpath: 'Srf22',
                    name: ''
                  },
                  {
                    meshpath: 'Srf115',
                    name: ''
                  },
                  {
                    meshpath: 'Srf23',
                    name: ''
                  },
                  {
                    meshpath: 'Srf116',
                    name: ''
                  },
                  {
                    meshpath: 'Srf24',
                    name: ''
                  },
                  {
                    meshpath: 'Srf117',
                    name: ''
                  },
                  {
                    meshpath: 'Srf25',
                    name: ''
                  },
                  {
                    meshpath: 'Srf118',
                    name: ''
                  },
                  {
                    meshpath: 'Srf26',
                    name: ''
                  },
                  {
                    meshpath: 'Srf119',
                    name: ''
                  },
                  {
                    meshpath: 'Srf27',
                    name: ''
                  },
                  {
                    meshpath: 'Srf120',
                    name: ''
                  },
                  {
                    meshpath: 'Srf28',
                    name: ''
                  },
                  {
                    meshpath: 'Srf121',
                    name: ''
                  },
                  {
                    meshpath: 'Srf29',
                    name: ''
                  },
                  {
                    meshpath: 'Srf122',
                    name: ''
                  },
                  {
                    meshpath: 'Srf210',
                    name: ''
                  },
                  {
                    meshpath: 'Srf123',
                    name: ''
                  },
                  {
                    meshpath: 'Srf211',
                    name: ''
                  },
                  {
                    meshpath: 'Srf124',
                    name: ''
                  },
                  {
                    meshpath: 'Srf125',
                    name: ''
                  },
                  {
                    meshpath: 'Srf126',
                    name: ''
                  },
                  {
                    meshpath: 'Srf127',
                    name: ''
                  },
                  {
                    meshpath: 'Srf128',
                    name: ''
                  },
                  {
                    meshpath: 'Srf129',
                    name: ''
                  },
                  {
                    meshpath: 'Srf130',
                    name: ''
                  }
                ],
                effect: 'pulse',
                description: 'Plaats hier uw aanvullende montageinstructies'
              },
              {
                id: 5,
                name: 'Afronden',
                changes: [{ id: 0, meshpath: '*', name: '*' }],
                description: 'Plaats hier uw aanvullende montageinstructies',
                files: [
                  {
                    id: 0,
                    value: './img/step-demo/21gr1.gif',
                    type: 'img',
                    title: 'Good job!'
                  }
                ]
              }
            ]
          : [
              ...[
                ...MOREFUCKINGSTEPS.map(({ meshpath, name }, i) => {
                  return {
                    id: i,
                    name: 'Plaatsing onderdeel',
                    effect: 'pulse',
                    changes: [{ id: 0, meshpath, name }]
                  };
                })
              ]
            ]
      ),
    [MOREFUCKINGSTEPS, projectInformation?.id]
  );

  return <StepBasedContext.Provider value={value}>{children}</StepBasedContext.Provider>;
}
export function useStepBasedContext() {
  return useContext(StepBasedContext);
}
